<template>
  <a-list
    item-layout="vertical"
    size="large"
    :pagination="listData.length > 0 && page ? pagination : null"
    :data-source="listData"
    :loading="loading"
    class="dingdang-list"
    :locale="{
      emptyText: '没有找到你要的文章啦~',
    }"
  >
    <template #renderItem="{ item }">
      <a-list-item key="item.title">
        <a-list-item-meta>
          <template #title>
            <router-link :to="`/web/${category}/detail/${item.id}`">
              <h3 style="font-weight: bold">{{ item.attributes.title }}</h3>
            </router-link>
          </template>
          <template #description>
            <div>
              <p>
                {{
                  dayjs(item.attributes.publishedAt).format(
                    "YYYY-MM-DD HH:mm:ss"
                  )
                }}
                <span style="padding-left:20px"> <eye-outlined />{{ item.attributes.views }}</span>
              </p>
              <p style="font-size: 16px; height: 70px">
                <a-typography-paragraph
                  :ellipsis="{
                    rows: 3,
                  }"
                >
                <router-link :to="`/web/${category}/detail/${item.id}`">
                    <p style="color:#333">{{item.attributes.description}}</p>
                  </router-link>
                </a-typography-paragraph>
              </p>
              <a-row justify="space-between">
                <!-- <a-col :span="4">
                  <router-link :to="`/web/${category}/detail/${item.id}`">
                    <a-button> 查看更多 </a-button>
                  </router-link>
                </a-col> -->
                <a-col :span="24" style="text-align: right; line-height: 32px">
                <a-typography-paragraph
                  :ellipsis="{
                    rows: 1,
                  }"
                >
                
                  <a-tag
                    :color="tag.attributes.color || randomColor()"
                    v-for="tag in item.attributes.tags.data"
                    :key="tag.id"
                  >
                    {{ tag.attributes.tag_name }}
                  </a-tag>
                </a-typography-paragraph>
                  
                </a-col>
              </a-row>
            </div>
          </template>
          <template #avatar>
            <img
              width="272"
              height="190"
              alt="logo"
              :src="
                `https://cms.dingdang.tw` +
                item.attributes.image.data.attributes.url
              "
            />
          </template>
        </a-list-item-meta>
      </a-list-item>
    </template>
  </a-list>
</template>

<script>
import { defineComponent, ref, onMounted, watch } from "vue";
import { useRoute, onBeforeRouteUpdate } from "vue-router";
import request from "@/utils/request";
import { EyeOutlined } from "@ant-design/icons-vue";
import {randomColor} from "../../utils/utils"
const qs = require("qs");
import dayjs from "dayjs";

export default defineComponent({
  components: {EyeOutlined},
  props: {
    searchVal: String,
    page: {
      type: Boolean,
      default: true,
    },
    pageSize: {
      type: Number,
      default: 10,
    },
    sort: {
      type: Array,
      default: () => ["publishedAt:desc"],
    },
  },
  setup(props) {
    const loading = ref(false);
    const listData = ref([]);
    const route = useRoute();
    const category = ref("");
    const tag = ref("");
    category.value = route.params.category;
    tag.value = route.query.tag;
    const pagination = {
      onChange: (page) => {
        console.log(page);
        pagination.page = page;
        queryList();
      },
      pageSize: props.pageSize,
    };

    const queryList = async (keyword) => {
      console.log(route.params, "参数", route.query);
      loading.value = true;
      const query = qs.stringify(
        {
          sort: props.sort,
          filters: {
            category: {
              name: {
                $eq: category.value,
              },
            },
            tags: {
              tag_name: {
                $eq: tag.value,
              },
            },
            $or: [
              {
                content: {
                  $containsi: keyword || "",
                },
              },
              {
                title: {
                  $containsi: keyword || "",
                },
              },
            ],
          },
          populate: "*",
          // fields: ["title"],
          pagination: {
            pageSize: pagination.pageSize,
            page: pagination.page,
          },
          // publicationState: "live",
          // locale: ["en"],
        },
        {
          encodeValuesOnly: true, // prettify url
        }
      );
      try {
        const response = await request.get(`/articles?${query}`);
        listData.value = response.data.data;
        pagination.total = response.data.meta.pagination.total;

        loading.value = false;
      } catch (error) {
        // this.error = error;
      }

      console.log(listData);
    };

    watch(
      () => props.searchVal,
      (newProps) => {
        console.log(newProps, "查看新值"); //查看新值
        queryList(newProps);
      }
    );

    // 路由变化
    onBeforeRouteUpdate((to) => {
      console.log("路有变化", to);
      category.value = to.params.category;
      tag.value = to.query.tag;
      queryList();
    });

    onMounted(() => {
      let keyword = route.query.keyword;
      queryList(keyword);
    });
    return {
      selectedKeys: ref(["2"]),
      pagination,
      loading,
      listData,
      category,
      randomColor
    };
  },
  data() {
    return {
      search: "",
      dayjs: dayjs,
    };
  },
});
</script>
<style>
.dingdang-list {
  min-height: 800px;
  /* margin-top: 1px; */
  background: #fff;
}
.dingdang-list .ant-list-empty-text {
  margin: 200px;
  font-size: 20px;
}
.dingdang-list .ant-list-pagination {
  padding-bottom: 20px;
  padding-right: 30px;
}
</style>
